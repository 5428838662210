
import AtlasSelTools from '@atlas/atlas-selection';
import ConvertIds from '@atlas/atlas-convert-ids';
import odd from "@atlas/on-demand-data";

let sel = null;
let context = null;
let elements = {};
let SPLM = null;
let viewManager = null;
let Visualization = null;
let apiId = 0;
let sendApiPromise = null;

let initSelection = (SPLM_in, controlManager_in, Visualization_in, 
                     sendApiPromise_in, svgOverlay) => {
    SPLM = SPLM_in;
    Visualization = Visualization_in;
    viewManager = controlManager_in.viewer;
    sendApiPromise = sendApiPromise_in;

    odd.initialize(controlManager_in);

    const sendApiPromiseSel = (parms) => {
        return sendApiPromise(parms.API, parms.ApiDescription);
    }

    sel = new AtlasSelTools.AtlasSelection(viewManager, Visualization);

    elements.partEl = new AtlasSelTools.BasicElements.PartElement(document.viewManager);
    elements.bodyEl = new AtlasSelTools.BasicElements.BodyElement(document.viewManager);
    elements.featureEl = new FeatureElement(document.viewManager);
    elements.curveEl = new AtlasSelTools.BasicElements.CurveElement(document.viewManager);
    elements.edgeEl = new AtlasSelTools.BasicElements.EdgeElement(document.viewManager);
    elements.edgeChainEl = new EdgeChainElement(document.viewManager);
    elements.faceEl = new AtlasSelTools.BasicElements.FaceElement(document.viewManager);
    elements.pmiEl = new AtlasSelTools.BasicElements.PmiElement(document.viewManager);
    elements.datumPlaneEl = new AtlasSelTools.BasicElements.DatumPlaneElement(document.viewManager);
    elements.snapEl = new AtlasSelTools.SnapPointElement(controlManager_in, Visualization, svgOverlay, SPLM, sendApiPromiseSel, sel );
    let els = [elements.snapEl, elements.datumPlaneEl, elements.edgeEl, elements.curveEl, elements.edgeChainEl, elements.faceEl, 
               elements.featureEl, elements.bodyEl, elements.partEl, 
               elements.pmiEl];

    context = new AtlasSelTools.Context(viewManager, els);
    sel.pushContext(context);
    setPickMode();

    sel.addSelectionListener(showSelectedObject);
    // Demo of filtering snap points
    //sel.addPreselectionListener(onlyCenterPoints);

}

function setPickMode()
{
    if (document.viewManager && sel)
        {
        let activeEls=[];
        if (document.getElementById("pickPoint").checked){
            activeEls.push(elements.snapEl);
        }
        activeEls.push(elements.datumPlaneEl);
        if (document.getElementById("pickEdge").checked){
            activeEls.push(elements.edgeEl);
            activeEls.push(elements.curveEl);
        }
        if (document.getElementById("pickEdgeChain").checked){
            activeEls.push(elements.edgeChainEl);
        }        
        if (document.getElementById("pickFace").checked){
            activeEls.push(elements.faceEl);
        }
        if (document.getElementById("pickFeature").checked){
            if (Object.keys(featCache).length == 0)
                loadFeats();
            activeEls.push(elements.featureEl);
        }
        if (document.getElementById("pickBody").checked){
            activeEls.push(elements.bodyEl);
        }
        if (document.getElementById("pickPart").checked){
            activeEls.push(elements.partEl);
        }        
        activeEls.push(elements.pmiEl);
        
        sel.setActive(activeEls, true, true);
    }
}


let chainCache = {};
let _chainId = 0;
let _edgeQueried = new Set();

const getEdgeChainRule = async(edgeAtlasId) => {
    let splmApiCached = SPLM.api;
    SPLM.api = [];

    let theSession = new SPLM.Session();
    let workPart = theSession.Parts.Work;

    var startEdge = new SPLM.Edge(edgeAtlasId);
    let rule = workPart.ScRuleFactory.CreateRuleEdgeTangent(startEdge, null, true, 0.5, false);


    var scC = workPart.ScCollectors.CreateCollector();

    scC.ReplaceRules([rule], false);
    scC.GetObjects();

    let api = null;
    try {
        api = await sendApiPromise(SPLM.api, "Sel_"+(++apiId), 1);
    }
    catch(err) {
     /*eslint no-console: ["error", { allow: ["log"] }] */
        console.log(err);      
    }
    let info = document.viewManager.getProductStructureInfo(document.viewManager.psId, [], 1);
    let modelId = info.children[info.children.length-1].psId.split(":")[0];

    if (api && api.API_RESULT != null && api.API_RESULT.length > 4 &&
        api.API_RESULT[4].GetObjects && api.API_RESULT[4].GetObjects.objects) {
        let thisChainId = _chainId++;
        let selObj = {id:"EdgeChain "+thisChainId, recipe:rule, psIds:[], type:"EdgeChain" };
        for (let chainObj of api.API_RESULT[4].GetObjects.objects){
            let psId = ConvertIds.toPsId(modelId, chainObj.ObjectId);
            selObj.psIds.push(psId);
            chainCache[psId] = selObj;
        }
    }
    SPLM.api = splmApiCached;
}

class EdgeChainElement extends AtlasSelTools.BasicElements.EdgeElement {
    constructor (viewer){
        super(viewer);
    }
    ofObject(psId){
        // in deselect, selection will now start calling this method on inactive
        // elements.  In real elements that wouldn't be an issue but this is
        // extra chauncy test code, and can't handle stuff like assembly objects.
        // so for now, just return.
        if (!this.active)
            return;
        if ( document.viewManager.getObjectTypeByPsId( psId ) != 'Edge' )
            return;
        let chain = chainCache[psId];
        if (chain)
            return chain;
        let queried = _edgeQueried.has(psId);
        if (queried)
            return;

        getEdgeChainRule(ConvertIds.fromPsId(document.viewManager, psId));
        _edgeQueried.add(psId);
    }

    typesRequired(){
        return ["Edge"];
    }
    getPickingMask(){
        return Visualization.PickingMode.EDGE;
    }
    getSelectionObject(){
        //there is no atlas object for chains, always return nothing
        return;
    }
    getDisplayName(){ return "Tangent Chain";}
}

let featCache = {};
let featureList = [];
const loadFeats = async () => {
    featCache = {};
    featureList = [];
    let info = document.viewManager.getProductStructureInfo(document.viewManager.psId, [], 1);
    if(info.children === undefined)
    {
        return;
    }
    let splmApiCached = SPLM.api;
    SPLM.api = [];
    let modelId = info.children[info.children.length-1].psId.split(":")[0];

    var session = new SPLM.AtlasSession();
    session.SetPropertyPolicy("SPLM.Features.Feature", ["GetFeatureName"]);

    var session2 = new SPLM.AtlasSession();
	session2.SetPropertyPolicy("SPLM.Features.BodyFeature", ["GetFaces", "GetFeatureName"]);

    let theSession = new SPLM.Session();
    let workPart = theSession.Parts.Work;

    let featureCollection = new SPLM.Features.FeatureCollection();
    featureCollection.ToArray(workPart);
    let api = null;
    try {
        api = await sendApiPromise(SPLM.api, "Sel_"+(++apiId), 1);
    }
    catch(err) {
        console.log(err);      
    }
    if (api && api.PROPERTY_POLICY_RESULT){
        for (let feature of api.PROPERTY_POLICY_RESULT){
            let featFaces = feature.GetFaces ? feature.GetFaces.faces : undefined;
            let featData = { id: feature.ObjectId, psIds: [], featClass:feature.Class, type:"Feature" };
            if (feature.GetFeatureName)
                featData.displayName = feature.GetFeatureName.feature_name;
            if (featFaces) {
                for (let featFace of featFaces) {
                    let facePsId = ConvertIds.toPsId(modelId, featFace.ObjectId);
                    featData.psIds.push(facePsId);
                    let cacheEntry = featCache[facePsId];
                    if (cacheEntry) {
                        cacheEntry.push(featData);
                    }
                    else {
                        featCache[facePsId] = [featData];
                    }
                }
            }
        }
    }
    /* Before 1953...
    if (api && api.API_RESULT != null && api.API_RESULT.length > 2 &&
        api.API_RESULT[2].ToArray) {
        // result 0 and 1 are prop policies
        featureList = api.API_RESULT[2].ToArray.members;
        for (let feature of featureList) {
            let featFaces = feature.GetFaces ? feature.GetFaces.faces : undefined;
            let featData = { id: feature.ObjectId, psIds: [], featClass:feature.Class, type:"Feature" };
            if (feature.GetFeatureName)
                featData.displayName = feature.GetFeatureName.feature_name;
            if (featFaces) {
                for (let featFace of featFaces) {
                    let facePsId = ConvertIds.toPsId(modelId, featFace.ObjectId);
                    featData.psIds.push(facePsId);
                    let cacheEntry = featCache[facePsId];
                    if (cacheEntry) {
                        cacheEntry.push(featData);
                    }
                    else {
                        featCache[facePsId] = [featData];
                    }
                }
            }
        }
    }
    */
    SPLM.api = splmApiCached;
}

class FeatureElement extends AtlasSelTools.BasicElements.SelectionElement {
    constructor (viewer){
        super(viewer);
    }
    ofObject(psId){
        return featCache[psId];
    }
    typesRequired(){
        return ["Face"];
    }
    getPickingMask(){
        return Visualization.PickingMode.FACE;
    }
        getSelectionObject(modelId, atlasId){
        let psIds = [];
        let displayName = "";
        for (let feature of featureList) {
            if (feature.ObjectId == atlasId){
                let featFaces = feature.GetFaces ? feature.GetFaces.faces : [];
                for (let featFace of featFaces) {
                    let facePsId = ConvertIds.toPsId(modelId, featFace.ObjectId);
                    psIds.push(facePsId);
                }
                if (feature.getDisplayName)
                    displayName = feature.GetFeatureName.feature_name;
            }
        }
        return { id: atlasId, psIds: psIds, type: "Feature", displayName: displayName, sourceElement:this };
    }
    getDisplayName(){ return "Feature";}
}

let onlyCenterPoints = function (e){
    if (e.addCandidates){
        for (let obj of e.addCandidates){
            if (obj.type == "SnapPoint"){
                if (obj.result.type != "Center"){
                    e.accepted = false;
                }
            }
        }
    }
}

var showSelectedObject = function (e) {
    document.getElementById("selectionResult").innerText = '';
    let name='';
    let sels = Array.from(context.getSelected());
    let obj = e.addCandidates ? e.addCandidates[0] : sels.length > 0 ? sels[sels.length-1] : undefined;
        let id = obj ? obj.id : undefined;
    //let allCands = sel.findAllAcceptedCandidates();
    //console.log(allCands);
    if (id) {
        name = "(" + context.numSelected() + ") ";
        name += JSON.stringify(id);
        if (obj.displayName) name += " " + obj.displayName;
        document.getElementById("selectionResult").innerText = name;
        // let info = document.viewManager.getProductStructureInfo(document.viewManager.psId, null, 1);
        // let modelId = info.children[info.children.length-1].psId.split(":")[0];
        // sel.updateAtlasSelected(modelId, [{atlasId:["assy.prt","KQBA","cylinder.prt"], selElement:elements.partEl}],
        // undefined, false);
    }
}
const resetCaches = () => {
    chainCache = {}; _chainId = 0; _edgeQueried.clear();
    if (document.getElementById("pickFeature").checked){
        loadFeats();   
    }
    else{
        featCache = {};
        featureList = [];
    }    
}

export { showSelectedObject, initSelection, setPickMode, loadFeats, resetCaches, sel, ConvertIds }
