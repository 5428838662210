(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@pvw/plmvisweb"));
	else if(typeof define === 'function' && define.amd)
		define(["@pvw/plmvisweb"], factory);
	else if(typeof exports === 'object')
		exports["AtlasSelTools"] = factory(require("@pvw/plmvisweb"));
	else
		root["AtlasSelTools"] = factory(root["Visualization"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__pvw_plmvisweb__) => {
return 